<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>Cargar Pago</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <!-- REQUEST STATUS -->
        <!-- <vs-select v-model="dataStatus" label="Estado" class="mt-5 w-full" name="item-status" v-validate="'required'">
          <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in status_choices" />
        </vs-select>
        <span class="text-danger text-sm" v-show="errors.has('item-status')">{{ errors.first('item-status') }}</span> -->

        <!-- AMOUNT -->
        <vs-input
          disabled="disabled"
          icon-pack="feather"
          icon="icon-dollar-sign"
          label="Monto Pagado"
          v-model="dataAmount"
          class="mt-5 w-full"
          v-validate="{ required: true, regex: /\d+(\.\d+)?$/ }"
          name="item-cost" />
        <span class="text-danger text-sm" v-show="errors.has('item-amount')">{{ errors.first('item-amount') }}</span>

        <!-- PAYMENT DAY -->
        <date-picker :language="languages.es" v-model="dataPayDate" :first-day-of-week="1" placeholder="Fecha de Pago" class="mt-5 w-full" name="item-pay_date"></date-picker>

        <!-- Upload -->
        <!-- <vs-upload text="Upload Image" class="img-upload" ref="fileUpload" /> -->

        <!-- Product Image -->
        <template v-if="dataReceipt">

          <!-- Image Container -->
          <div v-if="!this.isPdf" class="img-container w-64 mx-auto flex items-center justify-center mt-5">
            <img  :src="dataReceipt" alt="img" class="responsive">
          </div>
          <div v-else class="img-upload">
            <!-- <button type="button" class="btn-x-file"><i translate="translate" class="material-icons notranslate">clear</i></button> -->
            <h4 class="text-archive"><i translate="translate" class="material-icons notranslate">description</i><span>{{this.file_name}}</span></h4>
          </div>
          <span class="text-danger text-sm" v-show="errors.has('product_image')">{{ errors.first('product_image') }}</span>


          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5">
            <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*,application/pdf" name="product_image">
            <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">Actualizar Comprobante</vs-button>
            <!-- <vs-button type="flat" color="#999" @click="dataReceipt = null">Eliminar imágen</vs-button> -->
          </div>
          <!-- <div v-show="image_error" class="vs-alert con-icon"><i class="vs-icon notranslate icon-scale icon-alert material-icons null">new_releases</i><span class="text-danger text-sm">{{ image_error }}</span></div> -->
          <vs-alert v-show="image_error" color="danger" style="height: initial" icon-pack="feather" icon="icon-info">
            {{ image_error }}
          </vs-alert>
        </template>

        <div class="upload-img mt-5" v-if="!dataReceipt">
          <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*,application/pdf">
          <vs-button @click="$refs.uploadImgInput.click()">Subir Comprobante</vs-button>
          <vs-alert v-show="image_error" color="danger" class="mt-5" icon-pack="feather" icon="icon-info" style="min-height:60px">{{ image_error }}</vs-alert>
          <!-- <div v-show="image_error" class="vs-alert con-icon"><i class="vs-icon notranslate icon-scale icon-alert material-icons null">new_releases</i><span class="text-danger text-sm">{{ image_error }}</span></div> -->
        </div>

      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import vSelect from 'vue-select';
import DatePicker from "vuejs-datepicker";
import * as lang from 'vuejs-datepicker/src/locale/Language';

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    isSidebarActive(val) {

      if(!val) return
      if(Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        let { id, pay_date, receipt, status, total_pay, total_after_tax, commission_amount, type, recharges_total, final_amount_collector, id_advance_request, withholdings, amount_with_withholdings} = JSON.parse(JSON.stringify(this.data))
        this.dataId = id ? id : id_advance_request
        this.dataAmount = total_after_tax ? total_after_tax : total_pay ? (withholdings && withholdings.length > 0 ? amount_with_withholdings.toFixed(2) : total_pay.toFixed(2)) : commission_amount ? commission_amount.toFixed(2) : final_amount_collector ? final_amount_collector.toFixed(2) : 0
        this.dataPayDate = pay_date
        this.dataReceipt = (receipt !== null && receipt !== undefined ? this.$baseUrlImg + receipt : receipt)
        this.dataStatus = status
        this.dataType = type
        this.dataRecharges = recharges_total
        // Next line changed from: final_amount_collector ? final_amount_collector : 0 because if statement in line 188 would fail
        this.dataFinalAmountCollector = final_amount_collector
        this.initValues()
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  data() {
    return {

      dataId: null,
      dataAmount: 0,
      dataPayDate: new Date(),
      dataReceipt: "",
      dataStatus: "",
      dataType: false,
      dataRecharges: false,
      languages: lang,
      isPdf: false,
      file_name: "",

      product_image: {
        size: '',
        width: '',
        height: ''
      },

      image_loaded: false,
      image_error: '',

      status_choices: [],

      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    isFormValid() {
      return !this.errors.any() && this.dataPayDate && this.image_error == ''
    },
  },
  methods: {
    initValues() {
      if(this.data.id || this.data.id_advance_request) return
        this.dataId = null
        this.dataAmount = 0
        this.dataPayDate = new Date()
        this.dataReceipt = ""
        this.dataStatus = 0
        this.dataRecharges = false
    },
    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()

          let action = ''
          let obj = {}

          if (this.dataFinalAmountCollector !== undefined) {
            obj = {
                id_advance_request: this.dataId,
                advance_date: new Date(this.dataPayDate).toISOString().split("T")[0],
                receipt: (this.image_loaded) ? this.dataReceipt : ''
            }
            action = 'cancelAdvanceRequest'
          } else {
            obj = {
              id_payment_request: this.dataId,
              amount: this.dataAmount,
              pay_date: new Date(this.dataPayDate).toISOString().split("T")[0],
              receipt: (this.image_loaded) ? this.dataReceipt : '',
            }
            action = this.data.hasOwnProperty('total_after_tax') ? 'cancelPaymentRequestDirectPayment' : this.data.hasOwnProperty('id_store') ? 'cancelPaymentRequestStore' : this.dataType !== undefined ? "cancelPaymentRequestSuperAdmin" : this.dataRecharges !== undefined ? "cancelPaymentRequest" : "cancelPaymentRequestSchool";
          }

          this.dispatchAction(action, obj)
        }
      })
    },
    dispatchAction(action, data) {
      this.$store.dispatch(`reportModule/${action}`, data)
        .then(response => {
          if (response.status)
            this.showSuccess()
            this.$emit('closeSidebar')
            this.initValues()
            this.$vs.loading.close()
            this.$router.go()
        })
        .catch(err => {
          this.showError(err)
          this.$vs.loading.close()
        })
    },
    showSuccess() {
      this.$vs.notify({
        time: 8000,
        color: "success",
        title: "Solicitud pagada",
        text: "Se ha pagado correctamente la solicitud",
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-check',
      });
    },
    showError(msg) {
      this.$vs.notify({
        time: 8000,
        color: "danger",
        title: "ERROR",
        text: `${msg}`,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-alert-circle',
      });
    },
    updateCurrImg(input) {
      this.image_loaded = false;
      this.image_error = '';
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();

        if(input.target.files[0]['type'] === 'application/pdf') {
          if(input.target.files[0].size > 2000000) {
            this.image_error = "El archivo excede el tamaño máximo (2 MB)";
            return false;
          }
          reader.onload = e => {
            this.dataReceipt = e.target.result;
            this.image_loaded = true;
            this.file_name = input.target.files[0].name;
            this.isPdf = true;
          }
        } else {
          this.isPdf = false;
          this.product_image.size = input.target.files[0].size;
          reader.onload = e => {
            let img = new Image();
            img.onload = () => {
              this.product_image.width = img.width;
              this.product_image.height = img.height;
              if (this.product_image.width > 3000 ) {
                this.image_error = 'La imágen debe tener un tamaño máximo de 3000px';
              } else {
                if (this.product_image.size > 1000000){
                  this.image_error = 'La imágen excede el tamaño máximo (1 MB).';
                } else {
                  this.dataReceipt = e.target.result
                  this.image_loaded = true;
                }
              }
            }
            img.src = e.target.result;
          }
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
  },
  components: {
    VuePerfectScrollbar,
    'v-select': vSelect,
    DatePicker
  },
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
    p {
      margin-bottom: 2rem;
      > span {
        font-size: 0.9rem;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
